<template>
  <section
    id="features"
    class="grey lighten-3 px-3"
  >
    <v-container
      id="goals"
      class="py-5"
      tag="section"
    >
      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          class="d-flex"
          cols="12"
          sm="6"
          md="4"
        >
          <v-card
            outlined
            max-width="100%"
          >
            <v-img
              width="100%"
              height="200"
              color="grey"
              :src="feature.src" 
              />
            <v-card-title
              class="align-start"
              style="min-height: 88px;"
              v-text="feature.title"
            />
            <v-card-text
              v-text="feature.text"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
  export default {
    data: () => ({
       features: [
        {
          title: 'Professionaalsus',
          text: 'Meie tegevuse aluseks on hea erialase ettevalmistusega ning pikaajalise arhiivitöö kogemustega spetsialistid ning nõuetekohased arhivaalide hoiu- ja kasutamistingimused',
          //src: '/eba/static/archive.jpg',
          src: '/static/profesionaalsus_300_200.png',
        },
        {
          title: 'Andmeturve ja konfidentsiaalsus',
          text: 'Andmeturve, ärisaladuse kaitse ja konfidentsiaalsuse tagamine on meie teenuste aluseks ning seetõttu väärtustame pikaajalisi ja usalduslikke koostöösuhteid asutuste ja firmadega, kes hindavad enda loodud väärtusi ning võimalust säilitada dokumenteeritud teadmisi oma tegevuse ja saavutuste kohta',
          //src: '/eba/static/closed-folder.jpg',
          src: '/static/andmeturve_konfidentsiaalsus_465_200.png',
        },
        {
          title: 'Individuaalne lähenemine',
          text: 'Meie eesmärgiks on läheneda iga kliendi vajadustele individuaalselt ning pakkuda paindlikke lahendusi, sõltumatut nõu ja kvaliteetset teenust',
          //src: '/eba/static/archivist.jpg',
          //src: '/eba/static/human-resources.jpg',
          src: '/static/individuaalne_lahenemine_300_200.png',
        },
        {
          title: 'Kompleksne teenus',
          text: 'Meie filosoofiaks on informatsiooni professionaalne haldamine läbi terve selle elutsükli alates selle eesmärgipärasest loomisest, efektiivse kasutamise ja turvalise säilitamise kaudu kontrollitud hävitamiseni. Eestis oleme ainsad sellise kompleksteenuse pakkujad',
          //src: '/eba/static/document-conveyor.jpg',
          src: '/static/kompleksne_teenus_301_200.png',
        },
        {
          title: 'Kogemus',
          text: 'Eesti Äriarhiivi ekspertide grupp koosneb pikaajalise arhiivindusliku töökogemusega spetsialistidest. Oma meeskonna kujundamisel oleme eesmärgiks seadnud saavutada taskaal digitaalse- ja traditsioonilise paberdokumentidega asjaajamise ning -arhiivinduse teadmiste vahel, et pakkuda oma klientidele ajakohaseid ja professionaalseid lahendusi',
          //src: '/eba/static/working-with-documents.jpg',
          src: '/static/kogemus_300_200.png',
        },
        {
          title: 'Hoidlad',
          text: 'Oma teenuste pakkumiseks oleme välja arendanud tööruumid ja arhiivihoidlad Tartus ning Tallinnas – kokku üle 3000 m2. Arhiivi arendamisel ja säilitustingimustesse investeerimisel oleme seadnud eesmärgiks olla meie klientidele kaasaegne, arenev ja jätkusuutlik partner',
          //src: '/eba/static/archive-room.jpg',
          src: '/static/hoidlad_267_200.png',
        }
      ],
    })
  }
</script>
